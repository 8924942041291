import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ProposalEnquiryDetailProvider } from "presentation/store/ProposalEnquiry/ProposalEnquiryDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import ProposalEnquiryDetailMaintenance from "presentation/view/section/ProposalEnquiry/Detail/ProposalEnquiryDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ProposalEnquiryDetailContainer = () => <ANAInfoWrapper permission={Permission.PROPOSAL_ENQUIRY_DETAIL}>
    <ProposalEnquiryDetailProvider>
        <GridStyles/>
        <ProposalEnquiryDetailMaintenance/>
    </ProposalEnquiryDetailProvider>
</ANAInfoWrapper>;