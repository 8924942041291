import { EMPTY_PROPOSAL_ENQUIRY_ENTITY, ProposalEnquiryEntity } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";
import { EMPTY_STD_PROPOSAL_IS_ENTITY, StdProposalIsEntity } from "domain/entity/StandardProposal/StdProposalIsEntity";
import { EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface ProposalEnquiryDetailDropdownOptions {
    companyCodeDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: DropdownProps[],
    forwarderCodeDropdownOptions: DropdownProps[],
    currencyCodeDropdownOptions: DropdownProps[],
    tariffNatureDropdownOptions: DropdownProps[],
    proposalByDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],
}
export interface ProposalEnquiryDetailChangeState extends BaseViewChangeSate {
}

export interface ProposalEnquiryDetailModel {
    isLoading: boolean,
    dynamicOptions: ProposalEnquiryDetailDropdownOptions,
    detailState: ProposalEnquiryDetailChangeState,
    currentProposalEnquiry: ProposalEnquiryEntity,
    // standardProposalItemList: StandardProposalItemEntity[],
    // currentSelectItem: StandardProposalItemEntity,
    // currentSelectItemRows: StandardProposalItemEntity[],
    tariffItemTiers: StdProposalTariffItemTierEntity[],
    isShowAddTariffItemPanel: boolean,
    isAddTier: boolean,
    isAddIs: boolean,
    isEditTier: boolean,
    isEditIs: boolean,
    isAddTariffItem: boolean,
    currentTariffItemTier: StdProposalTariffItemTierEntity,
    tariffItemTierSelectedRows: StdProposalTariffItemTierEntity[],
    currentTariffItemIs: StdProposalIsEntity,
    tariffItemIsSelectedRows: StdProposalIsEntity[],
    initTariffCode: boolean,
    tariffCodeIds: string[],
}

export const EMPTY_PROPOSAL_ENQUIRY_DETAIL_MODEL: ProposalEnquiryDetailModel = {
    isLoading: false,
    currentProposalEnquiry: { ...EMPTY_PROPOSAL_ENQUIRY_ENTITY },
    detailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    },
    dynamicOptions: {
        companyCodeDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: [],
        forwarderCodeDropdownOptions: [],
        currencyCodeDropdownOptions: [],
        tariffNatureDropdownOptions: [],
        proposalByDropdownOptions: [],
        shiftCodeDropdownOptions: [],
    },
    // standardProposalItemList: [],
    // currentSelectItem: { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
    // currentSelectItemRows: [],
    tariffItemTiers: [],
    isShowAddTariffItemPanel: false,
    isAddTariffItem: false,
    isAddTier: false,
    isAddIs: false,
    isEditTier: false,
    isEditIs: false,
    initTariffCode: false,
    currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
    tariffCodeIds: [],
    tariffItemTierSelectedRows: [],
    tariffItemIsSelectedRows: [],
}