import { EMPTY_PROPOSAL_ENQUIRY_DETAIL_MODEL } from 'presentation/model/ProposalEnquiry/ProposalEnquiryDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: ProposalEnquiryDetailProvider,
    useTracked: useProposalEnquiryDetailTracked
} = createContainer(() => useState(EMPTY_PROPOSAL_ENQUIRY_DETAIL_MODEL), {concurrentMode: true});
export { ProposalEnquiryDetailProvider, useProposalEnquiryDetailTracked };

