import { ProposalEnquiryRepoImpl } from "domain/repository/Proposal/ProposalEnquiryRepoImpl";
import { useProposalEnquiryDetailTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryDetailProvider";
import { ProposalEnquiryDetailVM } from "presentation/viewModel/ProposalEnquiry/ProposalEnquiryDetailVM";
import { useMemo } from "react";

export const useProposalEnquiryDetailVM = () => {
    const [, setProposalEnquiryDetailState] = useProposalEnquiryDetailTracked();
    const proposalEnqDtlVM = useMemo(() =>
        ProposalEnquiryDetailVM({
            dispatch: [setProposalEnquiryDetailState],
            proposalEnquiryRepo: ProposalEnquiryRepoImpl(),
        }), [setProposalEnquiryDetailState])

    return proposalEnqDtlVM
}