import { ProposalEnquiryEntity } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";
import { ProposalEnquirySearchCriteria } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
import { StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { ProposalEnquiryRepository } from "./ProposalEnquiryRepo";

export const ProposalEnquiryRepoImpl = (): ProposalEnquiryRepository => {
    const url = '/v1/tariffProposalEnquiry';

    /**
     * Search the proposal by criteria
     * 
     * @param criteria 
     * @returns 
     */
    const searchTariffProposal = async (criteria: ProposalEnquirySearchCriteria): Promise<ProposalEnquiryEntity[]> => {
        const result = axiosPostData(tariffAxiosInstance, `${url}`, criteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
        return result;
    }


    /**
     * Fetch the tier list of a proposal.
     * 
     * @param proposalId
     * @returns 
     */

    const searchTierListById = async (proposalId: number): Promise<StdProposalTariffItemTierEntity[]> => {
        await axiosGetData(tariffAxiosInstance, `${url}/detail/${proposalId}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });

        return [];
    }


    /**
     * Update proposals.
     * 
     * @param proposals 
     * @returns 
     */
    const activateTariffProposals = async (proposals: ProposalEnquiryEntity[]): Promise<ProposalEnquiryEntity[]> => {
        // return await axiosPutData(tariffAxiosInstance, `${url}/activate`, proposals);
        const result = axiosPostData(tariffAxiosInstance, `${url}/activate`, proposals).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
        return result;

    }


    /**
     * Update proposals.
     * 
     * @param proposals 
     * @returns 
     */
    const deactivateTariffProposals = async (proposals: ProposalEnquiryEntity[]): Promise<ProposalEnquiryEntity[]> => {
        // return await axiosPutData(tariffAxiosInstance, `${url}/deactivate`, proposals);
        const result = axiosPostData(tariffAxiosInstance, `${url}/deactivate`, proposals).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
        return result;
    }
    return {
        searchTariffProposal: searchTariffProposal,
        searchTierListById: searchTierListById,
        activateTariffProposals: activateTariffProposals,
        deactivateTariffProposals: deactivateTariffProposals,
    }

}