import { CustomCellRendererProps } from "ag-grid-react";
import { StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ProposalEnquiryConstant } from "./ProposalEnquiryConstant";


const PROPOSAL_ENQ_DTL_CONST = ProposalEnquiryConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_PROPOSAL_ENQUIRY_DETAIL_COL_DEF: any[] = [
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.CYCLE,
        field: 'cycle',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 150,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.FOR_EVERY,
        field: 'forevery',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 150,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.UOM,
        field: 'uom',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 150,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.RATE,
        field: 'rate',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 150,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.MINIMUM,
        field: 'minimum',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 150,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.SURCHARGE,
        field: 'surcharge',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 150,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.SURCHARGE_TYPE,
        field: 'surchargeType',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 300,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.ADDITIONAL_UOM,
        field: 'additionalUom',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 300,
    },
    {
        headerName: PROPOSAL_ENQ_DTL_CONST.ADDITIONAL_RATE,
        field: 'additionalRate',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 300,
    },


].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: StdProposalTariffItemTierEntity[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
