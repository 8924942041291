import { EMPTY_PROPOSAL_ENQUIRY_MODEL } from 'presentation/model/ProposalEnquiry/ProposalEnquiryModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: ProposalEnquiryProvider,
    useTracked: useProposalEnquiryTracked
} = createContainer(() => useState(EMPTY_PROPOSAL_ENQUIRY_MODEL), {concurrentMode: true});
export { ProposalEnquiryProvider, useProposalEnquiryTracked };

