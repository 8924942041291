import { useProposalEnquiryDetailVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryDetailVM";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
// import { ProposalEnquiryHeaderFieldFormPanel } from "./Form/ProposalEnquiryHeaderFieldFormPanel";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { ProposalEnquiryHeaderFieldFormPanel } from "./Form/ProposalEnquiryHeaderFieldFormPanel";
import ProposalEnquiryDetailTablePanel from "./Table/ProposalEnquiryDetailTablePanel";


const ProposalEnquiryDetailMaintenance:React.FC = () => {

    const proposalEnqDetailVM = useProposalEnquiryDetailVM();
    const [proposalEnqState] = useProposalEnquiryTracked();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                const results = await Promise.allSettled([
                    proposalEnqDetailVM.onPageInit(proposalEnqState.currentProposal),
                    // proposalEnqDetailVM.onSearchTierList(proposalEnqState.currentProposal?.proposalId??0),

                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } else if(index === 1 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [proposalEnqDetailVM, proposalEnqState.currentProposal])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        {/* <div className="main-comp-wrapper"> */}
            {/* <ProposalEnquiryDetailTitleBar/> */}
            <SliderPanel
                isOpen={true}
                leftSectionWidth={"38%"}
                rightSectionWidth={"62%"}
                leftChildren={<ProposalEnquiryHeaderFieldFormPanel/>}
                rightChildren={<ProposalEnquiryDetailTablePanel/>}
            /> 
        {/* </div> */}
    </>
}

export default memo(ProposalEnquiryDetailMaintenance)